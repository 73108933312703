@import "./icon.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Poppins';
    src: url('/resources/fonts/Poppins-Bold.woff2') format('woff2'),
    url('/resources/fonts/Poppins-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/resources/fonts/Poppins-BlackItalic.woff2') format('woff2'),
    url('/resources/fonts/Poppins-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/resources/fonts/Poppins-LightItalic.woff2') format('woff2'),
    url('/resources/fonts/Poppins-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/resources/fonts/Poppins-MediumItalic.woff2') format('woff2'),
    url('/resources/fonts/Poppins-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/resources/fonts/Poppins-ExtraBold.woff2') format('woff2'),
    url('/resources/fonts/Poppins-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/resources/fonts/Poppins-Black.woff2') format('woff2'),
    url('/resources/fonts/Poppins-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/resources/fonts/Poppins-Regular.woff2') format('woff2'),
    url('/resources/fonts/Poppins-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/resources/fonts/Poppins-SemiBold.woff2') format('woff2'),
    url('/resources/fonts/Poppins-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/resources/fonts/Poppins-Medium.woff2') format('woff2'),
    url('/resources/fonts/Poppins-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/resources/fonts/Poppins-BoldItalic.woff2') format('woff2'),
    url('/resources/fonts/Poppins-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/resources/fonts/Poppins-ThinItalic.woff2') format('woff2'),
    url('/resources/fonts/Poppins-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/resources/fonts/Poppins-Italic.woff2') format('woff2'),
    url('/resources/fonts/Poppins-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/resources/fonts/Poppins-ExtraLightItalic.woff2') format('woff2'),
    url('/resources/fonts/Poppins-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/resources/fonts/Poppins-Thin.woff2') format('woff2'),
    url('/resources/fonts/Poppins-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/resources/fonts/Poppins-Light.woff2') format('woff2'),
    url('/resources/fonts/Poppins-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/resources/fonts/Poppins-SemiBoldItalic.woff2') format('woff2'),
    url('/resources/fonts/Poppins-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/resources/fonts/Poppins-ExtraLight.woff2') format('woff2'),
    url('/resources/fonts/Poppins-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/resources/fonts/Poppins-ExtraBoldItalic.woff2') format('woff2'),
    url('/resources/fonts/Poppins-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

html, body {
    color: #2F2F2F;
    font-family: 'Poppins', sans-serif;
}

.preview h1, .preview h2, .preview h3, .preview h4, .preview h5, .preview h6 {
    font-size: revert;
    font-weight: revert;
}

.preview p, .preview blockquote, .preview code {
    margin: revert;
}

.preview ul, .preview ol {
    list-style: revert;
    padding: revert;
    margin: revert;
}

.preview a {
    color: revert;
    text-decoration: revert;
}

.preview img {
    margin: 0 auto;
}

.dark-gray-bg {
    background-color: #333333;
}

.light-green-text {
    color: #24F08E;
}

.white-text {
    color: white;
}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;

    border-top: 40px solid gray;
}

a {
    color: #449fdb;
    outline: none;
}

.max-h-a-lot {
    max-height: 199rem;
}

.tree-bg {
    background: url("/resources/img/HomepageBanner.jpg") no-repeat;
    background-size: cover;
}

.search {
    transition: width .2s ease-in-out;
}

.search:focus {
    width: 20rem;
}

.footer-bg {
    background: url("/resources/img/HomepageFooter.jpg") no-repeat bottom;
    background-size: 100%;
}

.category-arrow {
    transform: rotate(90deg);
}

.img-wrapper {
    width: 20rem;
    height: 20rem;
}

.button-green {
    background-color: #24F08E;
    color: #0A482A;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.button:active {
    background-color: #24F08E;
    color: #0A482A;
}

.mobile-menu {
    clear: both;
    max-height: 0;
    transition: all .2s ease-out;
}

#mobile-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    background-color: #fff;
}

/* menu icon */

.menu-icon {
    cursor: pointer;
    display: block;
    margin: 0 auto;
    padding: 24px 20px;
    position: relative;
    user-select: none;
}

.menu-icon .navicon {
    background: #333;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 18px;
}

.menu-icon .navicon:before,
.menu-icon .navicon:after {
    background: #333;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
}

.menu-icon .navicon:before {
    top: 5px;
}

.menu-icon .navicon:after {
    top: -5px;
}

.menu-btn {
    display: none;
}

#mobile-menu .menu-btn:checked ~ ul.mobile-menu {
    max-height:240px;
    padding: 0.75rem 0;
    overflow: visible;
}

.menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
}

.menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
}

.menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
}

.menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
}


.iframe-responsive-wrapper::before {
    display: block;
    content: "";
    padding-top: 56.25%;
}

.iframe-responsive-wrapper {
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
}

.iframe-responsive {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.spinner {
    display: inline-block;
    margin: 20px;
    max-width: max-content;
    -webkit-animation-name: spin;
    -webkit-animation-duration: 1000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 1000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 1000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;

    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@-ms-keyframes spin {
    from { -ms-transform: rotate(0deg); }
    to { -ms-transform: rotate(360deg); }
}
@-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}